<template>
	<div>
		<el-dialog class="cus_dialog"  :visible.sync="show" :close-on-click-modal="false" width="490px" top="100px" @close="closePhone">
			<div style="width: 450px; height: 700px; border: 1px solid #000; overflow: hidden;">
				<iframe :src="'./h5/m.html?id='+id" frameborder="0" style="width: 100%; height: 100%;"></iframe>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			id: ""
		},data(){
			return {
				show:true
			}
		},
		methods:{
			closePhone(){
				this.$parent.closePhone()
			}
		}
	}
</script>

<style>
</style>
